import React, { ReactElement } from "react";
import MainBanner from "@components/endurancewarrantyprotect/mainBanner/mainBanner";
import { GetServerSideProps } from "next";
import { getPageProps } from "@services/initial-calls";
import EnduranceWarrantyProtectWrapper from "@components/layouts/endurancewarrantyprotectWrapper";
import ComprehensiveCoverage from "@components/endurancewarrantyprotect/comprehensiveCoverage";
import EnduranceProtectionPlans from "@components/endurancewarrantyprotect/enduranceProtectionPlans";
import WhyChooseEndurance from "@components/endurancewarrantyprotect/whyChooseEndurance";
import FAQ from "@components/endurancewarrantyprotect/faq";
import CarFeatures from "@components/shared/carFeatures";
import CustomerReviews from "@components/endurancewarrantyprotect/customerReviews";

export default function EnduranceWarrantyProtectHome(): ReactElement {
    return (
        <EnduranceWarrantyProtectWrapper>
            <div className="max-w-7xl m-auto px-4vw lg:px-4">
                <MainBanner />
                <ComprehensiveCoverage />
                <EnduranceProtectionPlans />
                <WhyChooseEndurance />
                <CarFeatures />
            </div>
            <CustomerReviews />
            <div className="max-w-7xl m-auto px-4vw lg:px-4">
                <FAQ />
            </div>
        </EnduranceWarrantyProtectWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
